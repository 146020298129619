import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { DebugHappeoThemeProvider } from "@happeouikit/theme";
import { Playground } from 'docz';
import { PackageMetadata, ColorExample, BackgroundBox, ShadeGrid } from '@happeo/docs-tools';
import pkg from "../package.json";
import { brandLight, brand, violet, white, white70, black, black70, black50, black30, active, alert, navy, success, warn, gray01, gray02, gray03, gray04, gray05, gray06, gray07, gray08, gray09, gray10, darken, lighten, darken80, darken60, darken40, darken20, lighten20, lighten40, lighten60, lighten80, lighten90 } from '../src/index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <DebugHappeoThemeProvider mdxType="DebugHappeoThemeProvider" />
    <h2 {...{
      "id": "new-colors-ds3---use-css-variables-and-theme-provider"
    }}>{`New Colors (DS3) - use CSS variables and theme provider!`}</h2>
    <p>{`From 2024 onwards, we should only be using CSS variables for colors (e.g. `}<inlineCode parentName="p">{`var(--color-active-primary)`}</inlineCode>{`, or
on rare occasions, colors from `}<inlineCode parentName="p">{`useTheme`}</inlineCode>{`.  These are defined and exported in `}<inlineCode parentName="p">{`@happeouikit/theme`}</inlineCode>{`.`}</p>
    <p>{`Additionally, many colors should be semantic - e.g. `}<inlineCode parentName="p">{`--color-surface`}</inlineCode>{` rather than `}<inlineCode parentName="p">{`--color-white100`}</inlineCode>{` - which will allow
us to support more themes, like Dark Mode, in the future.`}</p>
    <ColorExample hex="var(--color-navy800)" name="navy800" mdxType="ColorExample" />
    <ColorExample hex="var(--color-active-primary)" name="active-primary" mdxType="ColorExample" />
    <ColorExample hex="var(--color-success-primary)" name="success-primary" mdxType="ColorExample" />
    <ColorExample hex="var(--color-warning-primary)" name="warn" mdxType="ColorExample" />
    <ColorExample hex="var(--color-alert-primary)" name="alert-primary" mdxType="ColorExample" />
    <ColorExample hex="var(--color-violet800)" name="violet800" mdxType="ColorExample" />
    <h1 {...{
      "id": "please-dont-import-anything-from-this-package-anymore"
    }}>{`Please don't import anything from this package anymore!`}</h1>
    <p>{`We will gradually remove references to most of its contents by replacing them with CSS vars.`}</p>
    <p>{`We may keep using darken, lighten, and toBgColor for a while longer, but remember that most of the time you could be
replacing colors with direct CSS vars, or semantic ones -
e.g. `}<inlineCode parentName="p">{`darken(active, 0.2)`}</inlineCode>{` should become `}<inlineCode parentName="p">{`"var(--color-active-darken20)"`}</inlineCode>{`.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "primary-colors"
    }}>{`Primary Colors`}</h2>
    <p>{`Each primary color is exported by it's name, so use that rather than the HEX code.`}</p>
    <ColorExample hex={navy} name="navy" mdxType="ColorExample" />
    <ColorExample hex={active} name="active" mdxType="ColorExample" />
    <ColorExample hex={success} name="success" mdxType="ColorExample" />
    <ColorExample hex={warn} name="warn" mdxType="ColorExample" />
    <ColorExample hex={alert} name="alert" mdxType="ColorExample" />
    <ColorExample hex={violet} name="violet" mdxType="ColorExample" />
    <h2 {...{
      "id": "happeo-brand"
    }}>{`Happeo Brand`}</h2>
    <ColorExample hex={brand} name="brand" mdxType="ColorExample" />
    <ColorExample hex={brandLight} name="brandLight" mdxType="ColorExample" />
    <h2 {...{
      "id": "grayscale"
    }}>{`Grayscale`}</h2>
    <p>{`Grayscale is used primarily. When grayscale texts are used on top of an image or colorful background, choose Black and it’s opacities below`}</p>
    <ColorExample hex={gray01} name="gray01" darkText={false} mdxType="ColorExample" />
    <ColorExample hex={gray02} name="gray02" darkText={false} mdxType="ColorExample" />
    <ColorExample hex={gray03} name="gray03" darkText={false} mdxType="ColorExample" />
    <ColorExample hex={gray04} name="gray04" darkText={false} mdxType="ColorExample" />
    <ColorExample hex={gray05} name="gray05" darkText={false} mdxType="ColorExample" />
    <ColorExample hex={gray06} name="gray06" darkText={true} mdxType="ColorExample" />
    <ColorExample hex={gray07} name="gray07" darkText={true} mdxType="ColorExample" />
    <ColorExample hex={gray08} name="gray08" darkText={true} border={true} mdxType="ColorExample" />
    <ColorExample hex={gray09} name="gray09" darkText={true} border={true} mdxType="ColorExample" />
    <ColorExample hex={gray10} name="gray10" darkText={true} border={true} mdxType="ColorExample" />
    <h2 {...{
      "id": "black--white"
    }}>{`Black & white`}</h2>
    <div>
  <ColorExample hex={black} name="black" darkText={false} mdxType="ColorExample" />
  <ColorExample hex={black70} name="black70" darkText={false} mdxType="ColorExample" />
  <ColorExample hex={black50} name="black50" darkText={false} mdxType="ColorExample" />
  <ColorExample hex={black30} name="black30" darkText={false} mdxType="ColorExample" />
    </div>
    <BackgroundBox mdxType="BackgroundBox">
  <ColorExample hex={white} name="white" darkText={true} mdxType="ColorExample" />
  <ColorExample hex={white70} name="white70" darkText={true} mdxType="ColorExample" />
    </BackgroundBox>
    <h2 {...{
      "id": "lighten--darken"
    }}>{`Lighten & Darken`}</h2>
    <p>{`For darkening and lightening there is functions ready.`}</p>
    <ColorExample hex={darken(violet, 0.8)} name="darken(violet, 0.8)" mdxType="ColorExample" />
    <ColorExample hex={lighten(navy, 0.8)} name="lighten(navy, 0.8)" darkText={true} mdxType="ColorExample" />
    <p>{`Below in the list there is examples of all the primary colors shaded.`}</p>
    <ShadeGrid colors={{
      navy,
      active,
      success,
      warn,
      alert,
      violet
    }} shadeFns={{
      darken80,
      darken60,
      darken40,
      darken20,
      default: color => color,
      lighten20,
      lighten40,
      lighten60,
      lighten80,
      lighten90
    }} mdxType="ShadeGrid" />
    <Playground __position={10} __code={'<ColorExample hex={darken(violet, 0.8)} name=\"darken\" />\n<ColorExample hex={lighten(violet, 0.8)} name=\"lighten\" darkText={true} />\n<ColorExample hex={darken(\'var(--color-violet800)\', 0.8)} name=\"darken\" />\n<ColorExample\n  hex={lighten(\'var(--color-violet800)\', 0.8)}\n  name=\"lighten\"\n  darkText={true}\n/>'} __scope={{
      props,
      DefaultLayout,
      DebugHappeoThemeProvider,
      Playground,
      PackageMetadata,
      ColorExample,
      BackgroundBox,
      ShadeGrid,
      pkg,
      brandLight,
      brand,
      violet,
      white,
      white70,
      black,
      black70,
      black50,
      black30,
      active,
      alert,
      navy,
      success,
      warn,
      gray01,
      gray02,
      gray03,
      gray04,
      gray05,
      gray06,
      gray07,
      gray08,
      gray09,
      gray10,
      darken,
      lighten,
      darken80,
      darken60,
      darken40,
      darken20,
      lighten20,
      lighten40,
      lighten60,
      lighten80,
      lighten90,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ColorExample hex={darken(violet, 0.8)} name="darken" mdxType="ColorExample" />
  <ColorExample hex={lighten(violet, 0.8)} name="lighten" darkText={true} mdxType="ColorExample" />
  <ColorExample hex={darken("var(--color-violet800)", 0.8)} name="darken" mdxType="ColorExample" />
  <ColorExample hex={lighten("var(--color-violet800)", 0.8)} name="lighten" darkText={true} mdxType="ColorExample" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      